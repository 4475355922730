import { BsCalendar2CheckFill, BsDeviceSsdFill } from "react-icons/bs";
import { FaCalendarAlt, FaCity, FaCodeBranch, FaUser } from "react-icons/fa";

export const sidebarData = [
  {
    menu: "Organization Setup",
    icon: <FaCity size={16} />,
    link: "/organization",
  },
  {
    menu: "Employee Management",
    icon: <FaUser size={16} />,
    link: "/employee",
  },
  {
    menu: "Branch Management",
    icon: <FaCodeBranch size={16} />,
    link: "/branch",
  },
  {
    menu: "Device Management",
    icon: <BsDeviceSsdFill size={16} />,
    link: "/device",
  },
  {
    menu: "Holiday Management",
    icon: <BsDeviceSsdFill size={16} />,
    link: "/holiday",
  },
  {
    menu: "Attendance",
    icon: <BsCalendar2CheckFill size={16} />,
    link: "/attendance",
  },
  {
    menu: "Calendar",
    icon: <FaCalendarAlt size={16} />,
    link: "/calendar",
  },
];
