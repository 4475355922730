import React from "react";
import { FaEdit } from "react-icons/fa";
import { MdOutlineAdd } from "react-icons/md";
import { useDispatch } from "react-redux";
import { clearAllBranches } from "../../Pages/Branch/Redux/branchSlice";
import { clearAllDevices } from "../../Pages/Device/Redux/deviceSlice";
import { clearAllEmployee } from "../../Pages/Employee/Redux/employeeSlice";
import { clearAllHolidays } from "../../Pages/Holiday/Redux/holidaySlice";
import { organizationEditSuccess } from "../../Pages/Organization/Redux/organizationSlice";

const CommonCreateButton = ({
  types,
  showModal,
  setShowModal,
  title,
  createPermission,
  edit,
}) => {
  const dispatch = useDispatch();

  const handleUpdate = () => {
    dispatch(organizationEditSuccess());
  };
  return (
    <div>
      <div
        className="d-flex justify-content-end"
        style={{ minWidth: "200px" }}
        title={title}
      >
        {createPermission && (
          <button
            className={showModal ? "create-btn d-none" : "create-btn"}
            onClick={() => {
              setShowModal(true);
              if (types === "employee") {
                dispatch(clearAllEmployee());
              } else if (types === "branch") {
                dispatch(clearAllBranches());
              } else if (types === "device") {
                dispatch(clearAllDevices());
              } else if (types === "holiday") {
                dispatch(clearAllHolidays());
              } else if (types === "organization" && edit) {
                handleUpdate();
              }
            }}
            type="button"
          >
            {edit ? <FaEdit /> : <MdOutlineAdd size={36} />}
          </button>
        )}
      </div>
    </div>
  );
};

export default CommonCreateButton;
