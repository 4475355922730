import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get device
export const getDevice = createAsyncThunk(
  "device/getDevice",
  async (filter, { rejectWithValue }) => {
    try {
      const { data } = await API.getDevice(filter);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get particular page
export const getPageDevice = createAsyncThunk(
  "device/getPageDevice",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageDevice(number, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// create device
export const createDevice = createAsyncThunk(
  "device/createDevice",
  async (data, { rejectWithValue }) => {
    const { name, deviceId, branch } = data;
    try {
      const body = JSON.stringify({
        deviceName: name,
        deviceId,
        branchId: branch?.id,
      });
      const { data } = await API.createDevice(body);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateDevice = createAsyncThunk(
  "device/updateDevice",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    const { name, deviceId, branch } = values;
    try {
      const body = JSON.stringify({
        deviceName: name,
        deviceId,
        branchId: branch?.id,
      });
      const { data } = await API.updateDevice(id, body);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "device/handleSearch",
  async (filterData, { rejectWithValue }) => {
    const { search, postsPerPage, branch } = filterData;
    try {
      const { data } = await API.handleSearch(search, postsPerPage, branch);
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
