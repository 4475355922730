import React from "react";
import { useSelector } from "react-redux";
import "./index.css";
import TodayDate from "./Date";

const Footer = () => {
  const date = new Date();
  const collapsed = useSelector((state) => state.layout.collapsed);
  return (
    <>
      <footer className={collapsed ? "footer collapsed" : "footer"}>
        <div className=" footer-container">
          <div className="row">
            <div className="col-6">
              <TodayDate />
            </div>
            <div className="col-6">
              <div className="d-flex justify-content-end">
                <div className="">
                  Powered by: Soori Solutions © {date.getFullYear()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
