import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get Attendance
export const getAttendance = createAsyncThunk(
  "attendance/getAttendance",
  async (filterData, { rejectWithValue }) => {
    try {
      const { postsPerPage, id, filters } = filterData;
      const { data } = await API.getAttendance(postsPerPage, id, filters);
      return data;
    } catch (error) {
      return rejectWithValue("Failed.");
    }
  }
);

// get particular page
export const getPageAttendance = createAsyncThunk(
  "attendance/getPageAttendance",
  async (filterData, { rejectWithValue }) => {
    const { number, postsPerPage, filters } = filterData;
    try {
      const { data } = await API.getPageAttendance(
        number,
        postsPerPage,
        filters
      );
      return data;
    } catch (error) {
      rejectWithValue("Failed.");
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "attendance/handleSearch",
  async (filterData, { rejectWithValue }) => {
    const { search, postsPerPage, id, filters } = filterData;
    try {
      const { data } = await API.handleSearch(
        search,
        postsPerPage,
        id,
        filters
      );
      return data;
    } catch (error) {
      rejectWithValue("Failed to get Attendance");
    }
  }
);
