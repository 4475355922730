import moment from "moment";
import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import axiosInstance from "../../Utils/axios";
import AsyncSelect from "../CommonAsyncSelectField/AsyncSelect";

export const loadOptionsBranch = async (
  search,
  loadOptions,
  { limit, offset }
) => {
  const { data } = await axiosInstance(
    `/api/v1/branch-app/get-branch?search=${search}&offset=${offset}&limit=${limit}`
  );
  return {
    options: data.content,
    hasMore: data.totalElements > limit ? true : false,
    additional: {
      offset: data.totalElements > limit ? limit + 1 : limit,
      limit: limit + 10,
    },
  };
};

const FilterComponent = ({ filters, setFilters, types }) => {
  const { branch, startDate, endDate } = filters;

  const showBranchFilter = [
    "employee",
    "attendance",
    "dashboard",
    "device",
    "attendance",
  ];
  const hideDateFilter = ["employee", "dashboard", "device"];

  const closePicker = () => {
    setFilters({ ...filters, startDate: "", endDate: "" });
  };

  const handleClearAllFilters = () => {
    setFilters({
      ...filters,
      branch: null,
      startDate: "",
      endDate: "",
    });
  };

  return (
    <div className="filter-component">
      <h5>Apply Filters</h5>
      <hr />
      <div className="row">
        {showBranchFilter?.includes(types) && (
          <div className="col-12">
            <AsyncSelect
              value={branch}
              name="branch"
              label="Branch"
              isNotFormik={true}
              getOptionLabel={(option) => `${option?.branchName} `}
              getOptionValue={(option) => `${option?.id}`}
              onChange={(selected) => {
                setFilters({
                  ...filters,
                  branch: selected,
                });
              }}
              loadOptions={loadOptionsBranch}
              additional={{
                offset: 0,
                limit: 10,
              }}
            />
          </div>
        )}
        {!hideDateFilter?.includes(types) && (
          <div className="col-12">
            <label className="date-picker-label">Select Date Range</label>
            <DateRangePicker
              initialSettings={{
                ranges: {
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(6, "days"), moment()],
                  "Last 30 Days": [moment().subtract(29, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "month").endOf("month"),
                  ],
                },
                alwaysShowCalendars: false,
                maxDate: new Date(),
                autoUpdateInput: false,
                locale: {
                  format: "YYYY-MM-DD",
                  cancelLabel: "Clear",
                },
              }}
              onApply={(ev, picker) => {
                setFilters({
                  ...filters,
                  startDate: moment(picker.startDate).format("YYYY-MM-DD"),
                  endDate: moment(picker.endDate).format("YYYY-MM-DD"),
                });
              }}
              onCancel={(e) => {
                setFilters({ ...filters, startDate: "" });
                if (startDate !== "") {
                  setFilters({ ...filters, endDate: "" }); // Only set endDate if it's not already empty
                }
              }}
            >
              <span className="form-control form-daterangepicker daterange">
                {startDate === ""
                  ? "Select Date Range"
                  : startDate + " - " + endDate}
                {startDate && (
                  <span onClick={() => closePicker()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l3.646-3.647a.5.5 0 1 1 .708.708L8.707 8l3.647 3.646a.5.5 0 1 1-.708.708L8 8.707l-3.646 3.647a.5.5 0 0 1-.708-.708L7.293 8 3.646 4.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </span>
                )}
              </span>
            </DateRangePicker>
          </div>
        )}
        <div className="col-12">
          <button
            type="button"
            className="btn btn-sm btn-outline-danger mt-2 w-100"
            onClick={handleClearAllFilters}
          >
            Clear All Filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
