import { combineReducers } from "@reduxjs/toolkit";

import alertReducer from "../Redux/Alert/alertSlice";
import layoutReducer from "../Redux/Layout/layoutSlice";
import employeeReducer from "../Pages/Employee/Redux/employeeSlice";
import branchReducer from "../Pages/Branch/Redux/branchSlice";
import deviceReducer from "../Pages/Device/Redux/deviceSlice";
import holidayReducer from "../Pages/Holiday/Redux/holidaySlice";
import attendanceSlice from "../Pages/Attendance/Redux/attendanceSlice";
import organizationSlice from "../Pages/Organization/Redux/organizationSlice";
import dashboardSlice from "../Pages/Dashboard/Redux/dashboardSlice";
import calendarSlice from "../Pages/Calendar/redux/calendarSlice";

const rootReducer = combineReducers({
  alert: alertReducer,
  layout: layoutReducer,
  employee: employeeReducer,
  branch: branchReducer,
  device: deviceReducer,
  holiday: holidayReducer,
  attendance: attendanceSlice,
  organization: organizationSlice,
  dashboard: dashboardSlice,
  calendar: calendarSlice,
});

export default rootReducer;
