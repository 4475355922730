import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getAttendance = (postsPerPage, id, filters) =>
  axiosInstance.get(
    `api/v1/attendance-app/get-attendance?offset=0&limit=${postsPerPage}&employeeId=${id}&startDate=${
      filters?.startDate
    }&endDate=${filters?.endDate}&branchId=${
      filters?.branch ? filters?.branch?.id : ""
    }&sortBy=-id`
  );

//obtaining the particular page data from paginated data
export const getPageAttendance = (number, postsPerPage, filters) =>
  axiosInstance.get(
    `api/v1/attendance-app/get-attendance?offset=${
      (number - 1) * postsPerPage
    }&limit=${postsPerPage}&startDate=${filters?.startDate}&endDate=${
      filters?.endDate
    }&branchId=${filters?.branch ? filters?.branch?.id : ""}&sortBy=-id`
  );

//searching function
export const handleSearch = (search, postsPerPage, id, filters) =>
  axiosInstance.get(
    `api/v1/attendance-app/get-attendance?offset=0&limit=${postsPerPage}&employeeId=${id}&startDate=${
      filters?.startDate
    }&endDate=${filters?.endDate}&branchId=${
      filters?.branch ? filters?.branch?.id : ""
    }&search=${search}&sortBy=-id`
  );
