import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getHoliday = (postsPerPage) =>
  axiosInstance.get(
    `api/v1/holiday-app/get-holiday?offset=0&limit=${postsPerPage}&sortBy=-id`
  );

export const getAllHoliday = () =>
  axiosInstance.get(`api/v1/holiday-app/get-holiday?sortBy=-id&limit=0`);

//obtaining the particular page data from paginated data
export const getPageHoliday = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/holiday-app/get-holiday?offset=${
      (number - 1) * postsPerPage
    }&limit=${postsPerPage}&sortBy=-id`
  );

//creating function
export const createHoliday = (body) =>
  axiosInstance.post(`api/v1/holiday-app/add`, body);
//updating function
export const updateHoliday = (id, body) =>
  axiosInstance.patch(`api/v1/holiday-app/update-holiday/${id}`, body);

//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(
    `api/v1/holiday-app/get-holiday?offset=0&limit=${postsPerPage}&search=${search}&sortBy=-id`
  );
export const checkRedundantData = (date, cancelToken) =>
  axiosInstance.get(`api/v1/holiday-app/get-holiday?date=${date}`, {
    cancelToken: cancelToken.token,
  });
