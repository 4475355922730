import * as API from './api';
import { createAsyncThunk } from '@reduxjs/toolkit';


export const  getHolidays = createAsyncThunk(
  'calendar/getHolidays',
  async (year, { rejectWithValue }) => {

    try {
      const { data } = await API.getHolidays(year);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);