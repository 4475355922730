import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getDevice,
  createDevice,
  updateDevice,
  handleSearch,
  getPageDevice,
} from "./thunk";

const initialState = {
  devices: [],
  edit: false,
  device: null,
  count: null,
  last: false,
  loading: false,
  loadingNext: false,
  loadingUpdated: false,
};

export const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    deviceEditSuccess: (state, action) => {
      state.edit = true;
      state.device = state.devices.find(
        (device) => device.id === action.payload
      );
    },
    clearAllDevices: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.device = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPageDevice.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getPageDevice.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.devices = [...state.devices, ...action.payload.content];
      state.count = action.payload.totalElements;
      state.last = action.payload.last;
    });
    builder.addCase(getPageDevice.rejected, (state) => {
      state.loadingNext = false;
      state.devices = [];
      state.count = 0;
      state.last = false;
    });
    builder.addCase(createDevice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createDevice.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createDevice.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateDevice.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateDevice.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateDevice.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(
      isAnyOf(getDevice.pending, handleSearch.pending),
      (state) => {
        state.loadingDevice = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getDevice.fulfilled, handleSearch.fulfilled),
      (state, action) => {
        state.loadingDevice = false;
        state.devices = action.payload.content;
        state.count = action.payload.totalElements;
        state.last = action.payload.last;
      }
    );
    builder.addMatcher(
      isAnyOf(getDevice.rejected, handleSearch.rejected),
      (state) => {
        state.loadingDevice = false;
        state.devices = [];
        state.count = 0;
        state.last = false;
      }
    );
  },
});

export const { deviceEditSuccess, clearAllDevices } = deviceSlice.actions;

export default deviceSlice.reducer;
