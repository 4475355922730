import React, { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganization } from "../Redux/thunk";

import CommonCreateButton from "../../../Components/CommonCreateButton/CommonCreateButton.js";
import CommonPageHeader from "../../../Components/CommonPageHeader/CommonPageHeader";
import ListingSkeleton from "../../../Components/Skeleton/ListingSkeleton";
import { clearAllOrganizations } from "../Redux/organizationSlice.js";
import CreateOrganization from "./CreateOrganization";
import Organization from "./Organization";

const Modal = lazy(() => import("../../../Components/Modal/Modal"));

const title = "Organization";
const types = "organization";

const OrganizationListing = () => {
  const dispatch = useDispatch();
  const loadingOrganization = useSelector((state) => state.organization.loadingOrganization);
  const count = useSelector((state) => state.organization.count);
  const organizations = useSelector((state) => state.organization.organizations);
  const edit = useSelector((state) => state.organization.edit);

  const [showOrganizationModal, setShowOrganizationModal] = useState(false);

  useEffect(() => {
    dispatch(getOrganization());
  }, []);
  const createPermission = true;
  return (
    <>
      <div className="organization-wrapper">
        <CommonPageHeader
          title={title}
          showOrganizationModal={showOrganizationModal}
          types={types}
          loading={loadingOrganization}
          data={organizations}
          count={count}
        />

        {loadingOrganization && <ListingSkeleton />}
        {!loadingOrganization && <Organization setShowOrganizationModal={setShowOrganizationModal} />}
      </div>
      <CommonCreateButton
        types={types}
        title={title}
        showModal={showOrganizationModal}
        setShowModal={setShowOrganizationModal}
        createPermission={createPermission}
        edit={organizations.length > 0}
      />
      {showOrganizationModal && (
        <Suspense fallback={<div></div>}>
          <Modal
            showModal={showOrganizationModal}
            setShowModal={setShowOrganizationModal}
            header={edit ? "Update Organization" : "Add Organization"}
            types={types}
            edit={edit}
            size={"modal-md"}
          >
            <CreateOrganization dispatch={dispatch} showModal={showOrganizationModal} setShowModal={setShowOrganizationModal} />
          </Modal>
        </Suspense>
      )}
    </>
  );
};

export default OrganizationListing;
