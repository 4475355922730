import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { setCollapsed } from "../../Redux/Layout/layoutSlice";
import { sidebarData } from "./SidebarData";
import "./sidebar.css";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import logo from "../../assets/activ.webp";

const CrmSidebar = () => {
  const location = useLocation();
  const collapsed = useSelector((state) => state.layout.collapsed);
  const showModal = useSelector((state) => state.layout.showModal);
  const [toggled, setToggled] = useState(false);

  const collapseicon = useSelector((state) => state.layout.collapseIcon);

  const dispatch = useDispatch();

  const menuItemStyles = {
    root: {
      fontSize: "14px",
      fontWeight: 400,
      transition: "all 0.25s ease-out",
    },
    icon: {
      margin: "0px",
    },

    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },

    button: ({ level, active, disabled }) => {
      if (level === 0)
        return {
          padding: "0px 10px 0 0 ",
          "&:hover": {
            backgroundColor: "var(--blue-primary)",
            color: "white !important",
            borderRadius: "10px",
            transition: "all 0.25s ease-in",
          },
        };
      if (level === 1)
        return {
          color: "rgba(36, 34, 32, 0.86)",
          height: "max-content",
          "&:hover": {
            backgroundColor: "var(--blue-primary)",
            color: "white !important",
            marginLeft: collapsed ? "0px" : "20px",
            borderRadius: collapsed ? "5px" : "10px",
            transition: "all 0.25s ease-in",
          },
        };
      if (level === 2)
        return {
          color: "rgba(36, 34, 32, 0.56)",
          marginLeft: "10px",
          height: "50px",
          "&:hover": {
            backgroundColor: "var(--blue-primary)",
            color: "white !important",
            marginLeft: collapsed ? "0px" : "20px",
            borderRadius: collapsed ? "5px" : "10px",
            transition: "all 0.25s ease-in",
          },
        };
    },

    label: ({ open, level }) => {
      if (level === 0)
        return {
          padding: "15px",
        };
      if (level === 1)
        return {
          padding: collapsed ? "10px 5px" : " 10px 20px",
          "&:hover": {
            padding: collapsed ? "10px 0px" : "10px 0px",
          },
        };
    },
  };
  return (
    <>
      <div
        className="d-flex flex-column"
        style={{ position: "relative", height: "100vh" }}
      >
        <div className="sidebar-header d-flex justify-content-between align-items-center">
          {!collapsed && (
            <Link to="/">
              {collapsed ? null : (
                <div className="logo-container">
                  <img alt="logo" src={logo} />
                </div>
              )}
            </Link>
          )}
          {collapseicon && (
            <button
              type="button"
              className={"collapse-btn"}
              disabled={showModal}
              onClick={() => {
                dispatch(setCollapsed());
              }}
            >
              {collapsed ? (
                <IoIosArrowDroprightCircle />
              ) : (
                <IoIosArrowDropleftCircle />
              )}
            </button>
          )}
        </div>
        {!collapsed && (
          <Sidebar
            collapsedWidth="45px"
            className="sidebar h-100"
            collapsed={collapsed}
            toggled={toggled}
            breakPoint="md"
            transitionDuration={250}
            rootStyles={{
              overflow: "auto",
              padding: "10px",
              backgroundColor: "white",
              [`.ps-sidebar-container`]: {
                backgroundColor: "white",
              },
            }}
          >
            <div className="d-flex flex-column h-100 justify-content-between ">
              <div>
                <Menu menuItemStyles={menuItemStyles} transitionDuration={750}>
                  {sidebarData?.map((side) => {
                    const { menu, icon, link } = side;

                    return (
                      <MenuItem
                        active={location?.pathname === link}
                        component={<Link to={link} />}
                        key={menu}
                        icon={icon}
                      >
                        {menu}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
            </div>
          </Sidebar>
        )}
      </div>
    </>
  );
};

export default CrmSidebar;
