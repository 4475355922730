import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getEmployee,
  getPageEmployee,
  createEmployee,
  updateEmployee,
  handleSearch,
} from "./thunk";

const initialState = {
  employees: [],
  edit: false,
  employee: null,
  count: null,
  loading: false,
  loadingNext: false,
  last: false,
  loadingUpdated: false,
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    employeeEditSuccess: (state, action) => {
      state.edit = true;
      state.employee = state.employees.find(
        (employee) => employee.id === action.payload
      );
    },
    clearAllEmployee: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.employee = null;
      state.last = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPageEmployee.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getPageEmployee.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.edit = false;
      state.employees = [...state?.employees, ...action.payload.content];
      state.count = action.payload.totalElements;
      state.last = action.payload.last;
    });
    builder.addCase(getPageEmployee.rejected, (state) => {
      state.loadingNext = false;
      state.employees = [];
      state.count = 0;
      state.last = false;
      state.edit = false;
    });
    builder.addCase(createEmployee.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createEmployee.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createEmployee.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateEmployee.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateEmployee.rejected, (state) => {
      state.loadingUpdated = false;
    });

    builder.addMatcher(
      isAnyOf(getEmployee.pending, handleSearch.pending),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getEmployee.fulfilled, handleSearch.fulfilled),
      (state, action) => {
        state.loading = false;
        state.employees = action.payload.content;
        state.count = action.payload.totalElements;
        state.last = action.payload.last;
      }
    );
    builder.addMatcher(
      isAnyOf(getEmployee.rejected, handleSearch.rejected),
      (state) => {
        state.loading = false;
        state.employees = [];
        state.count = 0;
        state.last = false;
      }
    );
  },
});

export const { employeeEditSuccess, clearAllEmployee } = employeeSlice.actions;

export default employeeSlice.reducer;
