import { createSlice } from "@reduxjs/toolkit";
import { getCurrentEmployeeInformation, getYearlyData } from "./thunk";

const initialState = {
  loadingCurrentEmployeeInfo: false,
  currentEmployeeInfo: null,
  loadingYearlyData: false,
  yearlyData: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentEmployeeInformation.pending, (state) => {
        state.loadingCurrentEmployeeInfo = true;
      })
      .addCase(getCurrentEmployeeInformation.fulfilled, (state, action) => {
        state.loadingCurrentEmployeeInfo = false;
        state.currentEmployeeInfo = action.payload;
      })
      .addCase(getCurrentEmployeeInformation.rejected, (state) => {
        state.loadingCurrentEmployeeInfo = false;
        state.currentEmployeeInfo = null;
      });

    builder
      .addCase(getYearlyData.pending, (state) => {
        state.loadingYearlyData = true;
      })
      .addCase(getYearlyData.fulfilled, (state, action) => {
        state.loadingYearlyData = false;
        state.yearlyData = action.payload;
      })
      .addCase(getYearlyData.rejected, (state) => {
        state.loadingYearlyData = false;
        state.yearlyData = null;
      });
  },
});

export default dashboardSlice.reducer;
