import React, { useEffect, useState } from "react";
import "./CommonPageHeader.css";
import "../CommonDatePicker/DatePicker.css";
import "react-datepicker/dist/react-datepicker.css";
import Tippy from "@tippyjs/react";
import { IoFilter } from "react-icons/io5";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import FilterComponent from "./FilterComponent";

const CommonPageHeader = ({
  title,
  types,
  search,
  setSearch,
  loading,
  data,
  count,
  handleExportExcel,
  filters,
  setFilters,
  exportData
}) => {
  const [showParentAnimation] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const hideSearch = ["calendar", "dashboard","organization"];
  const hideFilter = ["calendar", "organization", "branch", "holiday"];
  const hideExcel = [
    "organization",
    "branch",
    "device",
    "calendar",
    "holiday",
    "dashboard",
  ];
  const handleKeyDown = (event) => {
    if (event.key === "Escape" && showFilter) {
      setShowFilter(false);
    } else if (event.ctrlKey && event.key === "f") {
      event.preventDefault();
      setShowFilter(true);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <>
      <div className="common-page-header px-4 d-flex align-items-center justify-content-between">
        <div
          className="d-flex justify-content-between align-items-end w-100"
          style={{ height: "40px" }}
        >
          <div
            className="d-flex align-items-end"
            style={{ height: "min-content", gap: "10px", width: "max-content" }}
          >
            <div className="title">{title}</div>
            <div className="showing-entries-filter">
              <>
                {loading ? (
                  <p className="d-flex justify-content-start fetching-data">
                    Fetching data<i className="period">.</i>
                    <i className="period">.</i>
                    <i className="period">.</i>
                  </p>
                ) : count === 0 ? (
                  <p className="d-flex justify-content-start">
                    No entries found.
                  </p>
                ) : count > 0 ? (
                  <p>
                    {data?.length} of {count} Entries
                  </p>
                ) : (
                  ""
                )}
              </>
            </div>
          </div>

          <div
            className="d-flex align-items-center"
            style={{
              gap: "20px",
              ...(types === "question"
                ? { zIndex: 1, top: 0, right: 0, position: "absolute" }
                : {}),
            }}
          >
            <div
              className={`d-flex justify-content-center flex-column ${
                showParentAnimation ? "show-animation" : ""
              }`}
            >
              <div className="d-flex" style={{ gap: "10px" }}>
                {!hideSearch.includes(types) && (
                  <input
                    style={{
                      width: "100%",
                      minWidth: "200px",
                      boxShadow: "0px 0px 19px 0px #e7e7e74d",
                      border: "none",
                      borderRadius: "8px",
                      padding: "8px 10px",
                      outline: "none",
                    }}
                    className="border"
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value.trimStart())}
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                  />
                )}
                {!hideFilter?.includes(types) && (
                  <div className="filter-wrapper">
                    <Tippy content="Filter">
                      <button
                        type="button"
                        className={
                          showFilter
                            ? "btn btn-md common-filter-button active-filter"
                            : "btn btn-md common-filter-button"
                        }
                        onClick={() => setShowFilter((prev) => !prev)}
                      >
                        <IoFilter fontSize={22} />
                      </button>
                    </Tippy>
                    {showFilter && (
                      <FilterComponent
                        filters={filters}
                        setFilters={setFilters}
                        types={types}
                      />
                    )}
                  </div>
                )}
                {!hideExcel?.includes(types) && (
                  <Tippy content="Export Excel">
                    <button
                      className="export-excel-button"
                      onClick={() => handleExportExcel && handleExportExcel()}
                    >
                      <PiMicrosoftExcelLogoFill />
                      &nbsp;Export
                    </button>
                  </Tippy>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonPageHeader;
