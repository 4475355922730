import * as API from "./api";
import { createAsyncThunk } from "@reduxjs/toolkit";

// get data
export const getCurrentEmployeeInformation = createAsyncThunk(
  "dashboard/getCurrentEmployeeInformation",
  async (filter, { rejectWithValue }) => {
    try {
      const { branch } = filter;
      const { data } = await API.getCurrentEmployeeInformation(branch);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getYearlyData = createAsyncThunk(
  "dashboard/getYearlyData",
  async (filter, { rejectWithValue }) => {
    try {
      const { branch } = filter;
      const { data } = await API.getYearlyData(branch);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
