import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getDevice = (filter) =>
  axiosInstance.get(
    `api/v1/device-app/get-device?offset=0&limit=${filter?.postsPerPage}&branch=${filter?.branch}&sortBy=-id`
  );

//obtaining the particular page data from paginated data
export const getPageDevice = (filter) =>
  axiosInstance.get(
    `api/v1/device-app/get-device?offset=${
      (filter?.number - 1) * filter?.postsPerPage
    }&limit=${filter?.postsPerPage}&branch=${filter?.branch}&sortBy=-id`
  );

//creating function
export const createDevice = (body) =>
  axiosInstance.post(`api/v1/device-app/add`, body);
//updating function
export const updateDevice = (id, body) =>
  axiosInstance.patch(`api/v1/device-app/update-device/${id}`, body);

//searching function
export const handleSearch = (search, postsPerPage, branch) =>
  axiosInstance.get(
    `api/v1/device-app/get-device?offset=0&limit=${postsPerPage}&branch=${branch}&search=${search}&sortBy=-id`
  );

export const checkRedundantData = (e, cancelToken) =>
  axiosInstance.get(
    `api/v1/device-app/get-device?deviceId=${e.target.value.trim()}`,
    {
      cancelToken: cancelToken.token,
    }
  );
