import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getEmployee = (filter) =>
  axiosInstance.get(
    `api/v1/employee-app/get-employee?offset=0&limit=${filter?.postsPerPage}&branch=${filter?.branch}&sortBy=-id`
  );

//obtaining the particular page data from paginated data
export const getPageEmployee = (filter) =>
  axiosInstance.get(
    `api/v1/employee-app/get-employee?offset=${
      (filter?.number - 1) * filter?.postsPerPage
    }&limit=${filter?.postsPerPage}&branch=${filter?.branch}&sortBy=-id`
  );

//creating function
export const createEmployee = (body) =>
  axiosInstance.post(`api/v1/employee-app/register`, body, {
    headers: { "Content-Type": "multipart/form-data" },
  });
//updating function
export const updateEmployee = (id, body) =>
  axiosInstance.patch(`api/v1/employee-app/update-employee/${id}`, body, {
    headers: { "Content-Type": "multipart/form-data" },
  });

//searching function
export const handleSearch = (search, postsPerPage, branch) =>
  axiosInstance.get(
    `api/v1/employee-app/get-employee?offset=0&limit=${postsPerPage}&branch=${branch}&search=${search}&sortBy=-id`
  );
export const checkRedundantDataEmail = (e, cancelToken) =>
  axiosInstance.get(
    `api/v1/employee-app/get-employee?email=${e.target.value.trim()}`,
    {
      cancelToken: cancelToken.token,
    }
  );
export const checkRedundantDataPhoneNumber = (e, cancelToken) =>
  axiosInstance.get(
    `api/v1/employee-app/get-employee?phoneNo=${e.target.value.trim()}`,
    {
      cancelToken: cancelToken.token,
    }
  );
