import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getBranch = (postsPerPage) =>
  axiosInstance.get(
    `api/v1/branch-app/get-branch?offset=0&limit=${postsPerPage}&sortBy=-id`
  );

//obtaining the particular page data from paginated data
export const getPageBranch = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/branch-app/get-branch?offset=${
      (number - 1) * postsPerPage
    }&limit=${postsPerPage}&sortBy=-id`
  );

//creating function
export const createBranch = (body) =>
  axiosInstance.post(`api/v1/branch-app/add`, body);
//updating function
export const updateBranch = (id, body) =>
  axiosInstance.patch(`api/v1/branch-app/update/${id}`, body);

//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(
    `api/v1/branch-app/get-branch?offset=0&limit=${postsPerPage}&search=${search}&sortBy=-id`
  );

// checking the redundant data
export const checkRedundantDataName = (e, cancelToken) =>
  axiosInstance.get(
    `api/v1/branch-app/get-branch?branchName=${e.target.value.trim()}`,
    {
      cancelToken: cancelToken.token,
    }
  );
export const checkRedundantDataCode = (e, cancelToken) =>
  axiosInstance.get(
    `api/v1/branch-app/get-branch?branchCode=${e.target.value.trim()}`,
    {
      cancelToken: cancelToken.token,
    }
  );
