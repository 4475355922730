import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getAttendance, getPageAttendance, handleSearch } from "./thunk";

const initialState = {
  attendances: [],
  edit: false,
  count: null,
  next: null,
  previous: null,
  last: false,
  loading: false,
  loadingNext: false,
};

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPageAttendance.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getPageAttendance.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.edit = false;
      state.attendances = [...state?.attendances, ...action.payload.content];
      state.count = action.payload.totalElements;
      state.last = action.payload.last;
    });
    builder.addCase(getPageAttendance.rejected, (state) => {
      state.loadingNext = false;
      state.attendances = [];
      state.edit = false;
      state.count = 0;
      state.last = false;
    });

    builder.addMatcher(
      isAnyOf(getAttendance.pending, handleSearch.pending),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAttendance.fulfilled, handleSearch.fulfilled),
      (state, action) => {
        state.loading = false;
        state.attendances = action.payload.content;
        state.count = action.payload.totalElements;
        state.last = action.payload.last;
      }
    );
    builder.addMatcher(
      isAnyOf(getAttendance.rejected, handleSearch.rejected),
      (state) => {
        state.loading = false;
        state.attendances = [];
        state.count = 0;
        state.last = false;
      }
    );
  },
});

export default attendanceSlice.reducer;
