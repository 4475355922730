import { createSlice } from "@reduxjs/toolkit";
import {
  createOrganization,
  getOrganization,
  updateOrganization,
} from "./thunk";

const initialState = {
  organizations: [],
  edit: false,
  organization: null,
  count: null,
  loading: false,

  loadingUpdated: false,
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    organizationEditSuccess: (state) => {
      console.log("clicked");
      state.edit = true;
    },
    clearAllOrganizations: (state) => {
      state.organization = null;
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganization.pending, (state) => {
      state.loadingOrganization = true;
    });
    builder.addCase(getOrganization.fulfilled, (state, action) => {
      state.loadingOrganization = false;
      state.edit = false;
      state.organizations = action.payload.content;
      state.count = action.payload.totalElement;
    });
    builder.addCase(getOrganization.rejected, (state) => {
      state.loadingOrganization = false;
      state.organizations = [];
      state.count = 0;

      state.edit = false;
    });
    builder.addCase(createOrganization.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOrganization.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createOrganization.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateOrganization.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateOrganization.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateOrganization.rejected, (state) => {
      state.loadingUpdated = false;
    });
  },
});
export const { organizationEditSuccess, clearAllOrganizations } =
  organizationSlice.actions;
export default organizationSlice.reducer;
