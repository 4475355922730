import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ColumnResize from "react-table-column-resizer";
import { organizationEditSuccess } from "../Redux/organizationSlice";

import NoData from "../../../Components/NoData/NoData";

const Organization = ({ setShowOrganizationModal }) => {
  const dispatch = useDispatch();

  const organizations = useSelector((state) => state.organization.organizations);

  const handleEdit = (id) => {
    dispatch(organizationEditSuccess(id));
    setShowOrganizationModal(true);
  };

  return (
    <>
      {organizations?.length > 0 ? (
        <div className="row">
          <div className="col-12 table-scrollable">
            <table className="listing-table">
              <thead>
                <tr>
                  <th>SN</th>
                  <ColumnResize id={1} className="columnResizer" />
                  <th>Name</th>
                  <ColumnResize id={2} className="columnResizer" minWidth={120} />
                  <th>Start Time</th>
                  <ColumnResize id={3} className="columnResizer" minWidth={120} />
                  <th>End Time</th>
                  <ColumnResize id={4} className="columnResizer" minWidth={120} />
                </tr>
              </thead>
              <tbody>
                {organizations?.map((organization, i) => {
                  const { id, name, startTime, endTime } = organization;

                  return (
                    <tr key={id} onDoubleClick={() => handleEdit(id)} style={{ cursor: "pointer" }}>
                      <td>{i + 1}</td>
                      <td className="column_resizer_body" />
                      <td>{name ? name : "N/A"}</td>
                      <td className="column_resizer_body" />
                      <td>{startTime ? startTime : "N/A"}</td>
                      <td className="column_resizer_body" />
                      <td>{endTime ? endTime : "N/A"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <NoData />
      )}
    </>
  );
};

export default Organization;
