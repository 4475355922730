import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get holiday
export const getHoliday = createAsyncThunk("holiday/getHoliday", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getHoliday(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// get all holiday
export const getAllHoliday = createAsyncThunk("holiday/getAllHoliday", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllHoliday();
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// get particular page
export const getPageHoliday = createAsyncThunk("holiday/getPageHoliday", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getPageHoliday(number, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue(error);
  }
});

// create holiday
export const createHoliday = createAsyncThunk("holiday/createHoliday", async (data, { rejectWithValue }) => {
  const { name, date } = data;
  try {
    const body = JSON.stringify({
      name,
      date: new Date(date).toLocaleDateString("fr-CA"),
    });
    const { data } = await API.createHoliday(body);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateHoliday = createAsyncThunk("holiday/updateHoliday", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { name, date } = values;
  try {
    const body = JSON.stringify({
      name,
      date: new Date(date).toLocaleDateString("fr-CA"),
    });
    const { data } = await API.updateHoliday(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// handle search
export const handleSearch = createAsyncThunk("holiday/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue(error);
  }
});
