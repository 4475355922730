import React, { lazy, Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import PageNotFound from "../Components/PageNotFound";
import OrganizationListing from "../Pages/Organization/Pages";

//for refreshing the page when lazy fails loading the component
const lazyWithReload = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem("page-has-been-force-refreshed") || "false");
    try {
      const component = await componentImport();
      window.localStorage.setItem("page-has-been-force-refreshed", "false");
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem("page-has-been-force-refreshed", "true");
        return window.location.reload();
      }
      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      throw error;
    }
  });

const Dashboard = lazyWithReload(() => import("../Pages/Dashboard/Pages"));

// user setup
const EmployeeListing = lazyWithReload(() => import("../Pages/Employee/Pages"));
const DeviceListing = lazyWithReload(() => import("../Pages/Device/Pages"));
const BranchListing = lazyWithReload(() => import("../Pages/Branch/Pages"));
const HolidayListing = lazyWithReload(() => import("../Pages/Holiday/Pages"));
const Calendar = lazyWithReload(() => import("../Pages/Calendar/Pages"));
const Attendance = lazyWithReload(() => import("../Pages/Attendance/Pages"));
const Routes = () => {
  const ErrorFallback = ({ error }) => {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={""}>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/employee" component={EmployeeListing} />
          <Route exact path="/device" component={DeviceListing} />
          <Route exact path="/branch" component={BranchListing} />
          <Route exact path="/holiday" component={HolidayListing} />
          <Route exact path="/calendar" component={Calendar} />
          <Route exact path="/attendance" component={Attendance} />
          <Route exact path="/employee/attendance/:id" component={Attendance} />
          <Route exact path="/organization" component={OrganizationListing} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

export default withRouter(Routes);
