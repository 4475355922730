import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get branch
export const getBranch = createAsyncThunk(
  "branch/getBranch",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getBranch(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get particular page
export const getPageBranch = createAsyncThunk(
  "branch/getPageBranch",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageBranch(number, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// create branch
export const createBranch = createAsyncThunk(
  "branch/createBranch",
  async (data, { rejectWithValue }) => {
    const { name, code, address } = data;
    try {
      const body = JSON.stringify({
        branchName: name,
        branchCode: code,
        branchAddress: address,
      });
      const { data } = await API.createBranch(body);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateBranch = createAsyncThunk(
  "branch/updateBranch",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    const { name, code, address } = values;
    try {
      const body = JSON.stringify({
        branchName: name,
        branchCode: code,
        branchAddress: address,
      });
      const { data } = await API.updateBranch(id, body);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "branch/handleSearch",
  async (data, { rejectWithValue }) => {
    const { search, postsPerPage } = data;
    try {
      const { data } = await API.handleSearch(search, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
