import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get employee
export const getEmployee = createAsyncThunk(
  "employee/getEmployee",
  async (filter, { rejectWithValue }) => {
    try {
      const { data } = await API.getEmployee(filter);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get particular page
export const getPageEmployee = createAsyncThunk(
  "employee/getPageEmployee",
  async (filter, { rejectWithValue }) => {
    try {
      const { data } = await API.getPageEmployee(filter);
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// create employee
export const createEmployee = createAsyncThunk(
  "employee/createEmployee",
  async (data, { rejectWithValue }) => {
    const { name, email, phoneNumber, address, branch, photo, gender } = data;
    try {
      const body = new FormData();
      body.append("fullName", name);
      body.append("email", email);
      body.append("phoneNo", phoneNumber);
      body.append("address", address);
      body.append("branchId", branch?.id);
      body.append("gender", gender?.id);
      body.append("photo", photo);

      const { data } = await API.createEmployee(body);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateEmployee = createAsyncThunk(
  "employee/updateEmployee",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    const { name, email, phoneNumber, address, branch, photo, gender } = values;
    try {
      const body = new FormData();
      body.append("fullName", name);
      body.append("email", email);
      body.append("phoneNo", phoneNumber);
      body.append("address", address);
      body.append("branchId", branch?.id);
      body.append("gender", gender?.id);
      if (photo !== "") {
        body.append("photo", photo);
      }
      const { data } = await API.updateEmployee(id, body);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "employee/handleSearch",
  async (filterData, { rejectWithValue }) => {
    const { search, postsPerPage, branch } = filterData;
    try {
      const { data } = await API.handleSearch(search, postsPerPage, branch);
      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
