import { Form, Formik } from "formik";
import React from "react";
// import { MdAccessTime } from "react-icons/md";
// import Picker from "react-mobile-picker";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { errorFunction, successFunction } from "../../../Components/Alert/Alert";
import Button from "../../../Components/Buttons/Button";
import Loader from "../../../Components/Loader";
import TextField from "../../../Components/TextField/TextField";
import { createOrganization, getOrganization, updateOrganization } from "../Redux/thunk";
import "./style.css";

const CreateOrganization = ({ dispatch, setShowModal }) => {
  const organizations = useSelector((state) => state.organization.organizations);
  const loading = useSelector((state) => state.organization.loading);
  const loadingUpdated = useSelector((state) => state.organization.loadingUpdated);
  const edit = useSelector((state) => state.organization.edit);

  const initialState = {
    name: edit ? organizations[0]?.name : "",
    startTime: edit ? organizations[0]?.startTime : "",
    endTime: edit ? organizations[0]?.endTime : "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required!"),
    startTime: Yup.string().required("Required!"),
    endTime: Yup.string().required("Required!"),
  });

  const onSubmit = (values) => {
    if (!edit) {
      dispatch(createOrganization(values))
        .unwrap()
        .then(() => {
          successFunction("Organization created successfully.");
          dispatch(getOrganization());
          setShowModal(false);
        })
        .catch(() => {
          errorFunction("Failed to create organization.");
        });
    } else {
      let updateData = { id: organizations[0]?.id, values };
      dispatch(updateOrganization(updateData))
        .unwrap()
        .then(() => {
          successFunction("Organization updated successfully.");
          dispatch(getOrganization());
          setShowModal(false);
        })
        .catch(() => errorFunction("Failed to update organization."));
    }
  };

  return (
    <>
      {(loading || loadingUpdated) && <Loader />}

      <Formik initialValues={initialState} validationSchema={validationSchema} onSubmit={onSubmit}>
        {(formik) => {
          return (
            <Form autoComplete="off">
              <div className="create-organization-wrapper">
                <div className="row">
                  <div className="col-12 ">
                    <TextField
                      type="text"
                      name="name"
                      placeholder="Name"
                      label="Name"
                      required
                      formikRequired={formik?.errors?.name && formik?.touched?.name}
                      onChange={(e) => {
                        formik.setFieldValue("name", e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <TextField
                      type="time"
                      name="startTime"
                      label="Start Time"
                      placeholder="00:00"
                      onChange={(e) => formik.setFieldValue("startTime", e.target.value)}
                    />
                  </div>
                  <div className="col-12 ">
                    <TextField
                      type="time"
                      name="endTime"
                      label="End Time"
                      placeholder="00:00"
                      onChange={(e) => formik.setFieldValue("endTime", e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 p-0 text-right">
                <div className="mt-3 d-flex justify-content-end align-items-center">
                  <Button
                    btnType="submit"
                    className="btn create-button"
                    createButton={true}
                    disabled={edit ? loadingUpdated : loading}
                    title={edit ? "Update" : "Save"}
                    content={edit ? "Update" : "Save"}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateOrganization;
