import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getBranch,
  getPageBranch,
  createBranch,
  updateBranch,
  handleSearch,
} from "./thunk";

const initialState = {
  branches: [],
  edit: false,
  branch: null,
  count: null,
  last: false,
  loading: false,
  loadingNext: false,
  loadingUpdated: false,
};

export const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    branchEditSuccess: (state, action) => {
      state.edit = true;
      state.branch = state.branches.find(
        (branch) => branch.id === action.payload
      );
    },
    clearAllBranches: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.branch = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPageBranch.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getPageBranch.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.edit = false;
      state.branches = [...state?.branches, ...action.payload.content];
      state.count = action.payload.totalElements;
      state.last = action.payload.last;
    });
    builder.addCase(getPageBranch.rejected, (state) => {
      state.loadingNext = false;
      state.branches = [];
      state.count = 0;
      state.last = false;
      state.edit = false;
    });
    builder.addCase(createBranch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBranch.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createBranch.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });

    builder.addCase(updateBranch.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateBranch.fulfilled, (state) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateBranch.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(
      isAnyOf(getBranch.pending, handleSearch.pending),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getBranch.fulfilled, handleSearch.fulfilled),
      (state, action) => {
        state.loading = false;
        state.branches = action.payload.content;
        state.count = action.payload.totalElements;
        state.last = action.payload.last;
      }
    );
    builder.addMatcher(
      isAnyOf(getBranch.rejected, handleSearch.rejected),
      (state) => {
        state.loading = false;
        state.branches = [];
        state.count = 0;
        state.last = false;
      }
    );
  },
});
export const { branchEditSuccess, clearAllBranches } = branchSlice.actions;
export default branchSlice.reducer;
