import { createSlice } from "@reduxjs/toolkit";
import { getHolidays } from "./thunk";

const initialState = {
  holidays: [],
  edit: false,
  loading: false,
  loadingUpdated: false,
  count: 0,
  calendar: null,
};

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHolidays.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHolidays.fulfilled, (state, action) => {
        state.loading = false;
        state.holidays = action.payload.content;
      })
      .addCase(getHolidays.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {} = calendarSlice.actions;

export default calendarSlice.reducer;
