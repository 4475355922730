import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getCurrentEmployeeInformation = (branch) =>
  axiosInstance.get(
    `api/v1/dashboard-app/current-employee-report?branchId=${
      branch !== null ? branch?.id : ""
    }`
  );
export const getYearlyData = (branch) =>
  axiosInstance.get(
    `api/v1/dashboard-app/yearly-employee-report?branch=${
      branch !== null ? branch?.id : ""
    }`
  );
