import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getHoliday,
  getAllHoliday,
  getPageHoliday,
  createHoliday,
  updateHoliday,
  handleSearch,
} from "./thunk";

const initialState = {
  holidays: [],
  edit: false,
  holiday: null,
  count: null,
  loading: false,
  loadingNext: false,
  loadingUpdated: false,
  last: false,
};

export const holidaySlice = createSlice({
  name: "holiday",
  initialState,
  reducers: {
    holidayEditSuccess: (state, action) => {
      state.edit = true;
      state.holiday = state.holidays.find(
        (holiday) => holiday.id === action.payload
      );
    },
    clearAllHolidays: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.holiday = null;
      state.last = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPageHoliday.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getPageHoliday.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.holidays = [...state.holidays, ...action.payload.content];
      state.count = action.payload.totalElements;
      state.last = action.payload.last;
    });
    builder.addCase(getPageHoliday.rejected, (state) => {
      state.loadingNext = false;
      state.holidays = [];
      state.count = 0;
      state.last = false;
    });
    builder.addCase(createHoliday.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createHoliday.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createHoliday.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateHoliday.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateHoliday.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateHoliday.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(
      isAnyOf(getHoliday.pending, getAllHoliday.pending, handleSearch.pending),
      (state) => {
        state.loadingHoliday = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getHoliday.fulfilled,
        getAllHoliday.fulfilled,
        handleSearch.fulfilled
      ),
      (state, action) => {
        state.loadingHoliday = false;
        state.holidays = action.payload.content;
        state.count = action.payload.totalElements;
        state.last = action.payload.last;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getHoliday.rejected,
        getAllHoliday.rejected,
        handleSearch.rejected
      ),
      (state) => {
        state.loadingHoliday = false;
        state.holidays = [];
        state.count = 0;
        state.last = false;
      }
    );
  },
});

export const { holidayEditSuccess, clearAllHolidays } = holidaySlice.actions;

export default holidaySlice.reducer;
