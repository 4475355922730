import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getOrganization = () => axiosInstance.get(`api/v1/organization-app/get-organization`);

//creating function
export const createOrganization = (body) => axiosInstance.post(`api/v1/organization-app/add`, body);
//updating function
export const updateOrganization = (id, body) => axiosInstance.patch(`api/v1/organization-app/update-organization/${id}`, body);

//searching function
export const handleSearch = () => axiosInstance.get(`api/v1/organization-app/get-organization`);
