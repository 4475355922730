import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get organization
export const getOrganization = createAsyncThunk("organization/getOrganization", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getOrganization();
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// create organization
export const createOrganization = createAsyncThunk("organization/createOrganization", async (data, { rejectWithValue }) => {
  const { name, startTime, endTime } = data;
  try {
    const body = JSON.stringify({ name, startTime, endTime });
    const { data } = await API.createOrganization(body);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateOrganization = createAsyncThunk("organization/updateOrganization", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { name, startTime, endTime } = values;
  try {
    const body = JSON.stringify({ name, startTime, endTime });
    const { data } = await API.updateOrganization(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
